<template>
  <div class="company-list-page">
    <v-snackbar v-model="snackbar_update" :timeout="3000" top color="success">
      <span>Update successful.</span>
      <v-btn @click="snackbar_update = false" text dark>Close</v-btn>
    </v-snackbar>

    <v-container style="max-width:1200px">
      <h1 class="mt-6 text-center">Edit Company - {{ loadedCompany.company_name }}</h1>
      <v-alert
        v-if="isExisting"
        type="warning"
      >That company already exists. Please choose a different company name, or add these investments under the existing company.</v-alert>
      <v-row align="center">
        <v-col xs="12" sm="6" md="4">
          <v-card class="pa-3 my-3" min-width="250px">
            <div class="overline mb-4 px-5">Company Details</div>
            <v-text-field
              label="Company Name"
              v-model="companyName"
              :hide-details="true"
              outlined
              class="mb-2"
              style="max-width:250px"
            />
            <v-text-field
              label="Company URL"
              v-model="companyURL"
              :hide-details="true"
              outlined
              class="mb-2"
              style="max-width:250px"
            />
            <v-combobox
              label="Industry"
              :items="industryList"
              v-model="industry"
              :hide-details="true"
              outlined
              class="mb-2"
              style="max-width:250px"
            />
            <v-row class="mx-0" align="end">
              <v-textarea
                label="Description"
                v-model="description"
                :hide-details="true"
                outlined
                class="mb-2"
                style="max-width:250px"
                rows="3"
              />
              <v-btn
                class="primary ml-5"
                @click="detailsUpdate"
                :disabled="!detailsChanged"
                :loading="isLoading"
              >Save</v-btn>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" sm="6" md="4">
          <v-card class="py-3 px-0 my-3" min-width="250px">
            <div class="overline mb-0 px-5">Performance (Coming Soon)</div>
          </v-card>
        </v-col>
        <v-col xs="12" sm="6" md="4">
          <v-card class="py-3 px-0 my-3" min-width="250px">
            <div class="overline mb-0 px-5">Funding History (Coming Soon)</div>
            <v-data-table
              :headers="fundingHeaders"
              :items="fundingValues"
              hide-default-footer
              class="mx-2"
              :disable-sort="true"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <InvestmentList :company-name="loadedCompany.company_name" />
  </div>
</template>

<script>
import InvestmentList from "../components/InvestmentList.vue";
import constants from "@/constants.js";
import { mapGetters } from "vuex";

export default {
  name: "EditCompany",
  components: { InvestmentList },
  props: ["id"],
  data: () => ({
    snackbar_update: false,
    isExisting: false,
    updates: { ...constants.newCompany, industry: null }, //this used to be companies as a list with one obj [{}]. Set industry to null since it's Not Assigned for each new
    industryList: [...constants.defaultIndustries],
    fundingHeaders: [
      { text: "Round", value: "round" },
      { text: "Date", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Valuation", value: "valuation" }
    ],
    fundingValues: [
      {
        round: "Pre-Seed",
        date: "2012-01-04",
        amount: "$500k",
        valuation: "$5.0M"
      },
      {
        round: "Seed",
        date: "2012-01-04",
        amount: "$1.0M",
        valuation: "$10.0M"
      },
      {
        round: "Bridge",
        date: "2012-01-04",
        amount: "$200k",
        valuation: "$10.0M"
      },
      {
        round: "Series A",
        date: "2012-01-04",
        amount: "$4.0M",
        valuation: "$40.0M"
      }
    ]
  }),
  computed: {
    ...mapGetters(["isLoading", "error"]),
    loadedCompany() {
      return this.$store.getters.loadedCompany(this.id);
    },
    companyName: {
      get() {
        return this.loadedCompany.company_name;
      },
      set(value) {
        this.updates.company_name = value;
      }
    },
    companyURL: {
      get() {
        return this.loadedCompany.companyURL;
      },
      set(value) {
        this.updates.companyURL = value;
      }
    },
    industry: {
      get() {
        return this.loadedCompany.industry;
      },
      set(value) {
        this.updates.industry = value;
      }
    },
    description: {
      get() {
        return this.loadedCompany.description;
      },
      set(value) {
        this.updates.description = value;
      }
    },
    detailsChanged() {
      if (
        this.updates.company_name !== null &&
        this.updates.company_name !== this.loadedCompany.company_name
      ) {
        return true;
      } else if (
        this.updates.companyURL !== null &&
        this.updates.companyURL !== this.loadedCompany.companyURL
      ) {
        return true;
      } else if (
        this.updates.industry !== null &&
        this.updates.industry !== this.loadedCompany.industry
      ) {
        return true;
      } else if (
        this.updates.description !== null &&
        this.updates.description !== this.loadedCompany.description
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    detailsUpdate() {
      let companyUpdates = {};

      companyUpdates.id = this.id;

      Object.keys(this.updates).forEach(key => {
        if (this.updates[key] !== null) {
          companyUpdates[key] = this.updates[key];
          if (key === "company_name") {
            let isExistingCompany = this.$store.getters.matchCompany(
              this.updates[key]
            );
            if (isExistingCompany) {
              this.isExisting = true;
            } else {
              this.isExisting = false;
            }
          }
        }
      });

      if (this.isExisting) {
        return;
      }

      this.$store.dispatch("updateCompany", companyUpdates).then(() => {
        this.snackbar_update = true;
      });

      // Reset temporary form states to blank
      Object.keys(this.updates).forEach(key => {
        this.updates[key] = null;
      });
    }
  }
};
</script>
